import React from "react"
import PropTypes from "prop-types"

import NavBar from "./navbar"
import Footer from "./footer"

const Layout = ({ children, location }) => (
  <>
    <NavBar pathname={location.pathname} />
    <div>
      <main>{children}</main>
      <Footer />
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

Layout.defaultProps = {
  location: {},
}

export default Layout

import React from "react"
import { Link } from "gatsby"
import "../plugins/fontawesome"

import SocialMedia from "../components/social-media"
import ReporteImpacto from "../assets/files/Estacion Meiquer – Reporte de Impacto 2019.pdf"
import AvisoPrivacidad from "../assets/files/Estacion Meiquer - Aviso de Privacidad.pdf"
import LogoMeiquer from "../assets/logos/logos-1x/logo-meiquer-star-bg.png"

const Footer = () => (
  <footer>
    <div className="footer-content columns is-variable is-8">
      <div className="column is-paddingless">
        <div className="footer-box has-text-centered">
          <img src={LogoMeiquer} alt="Estación MX Logo" />
          <p className="w-bold">Transformando</p>
          <p>
            Comunidades, <span className="s-italic">jugando</span>
          </p>
        </div>
      </div>
      <div className="column">
        <h5>Organización</h5>
        <Link className="footer-link" to="/nosotros">
          Nosotros
        </Link>
        <a className="footer-link" href={ReporteImpacto} download>
          Modelo de Impacto
        </a>
        <a className="footer-link" href={ReporteImpacto} download>
          Reporte de Impacto 2019
        </a>
        <a className="footer-link" href={AvisoPrivacidad} download>
          Aviso de privacidad
        </a>
        <div style={{ marginTop: "2rem" }}>
          <SocialMedia />
        </div>
      </div>
      <div className="column">
        <h5>Servicios</h5>
        <Link className="footer-link" to="/espiral-meiquer">
          Espiral Meiquer
        </Link>
        <Link className="footer-link" to="/meiquer-tv">
          MeiquerTV
        </Link>
        <Link className="footer-link" to="/experiencias-meiquer">
          Cursos Vacacionales
        </Link>
        <Link className="footer-link" to="/estaciones-juego">
          Estaciones de Juego
        </Link>
        <Link
          className="footer-link"
          to="/servicios-profesionales/meiquer-labs-historias"
        >
          Meiquer Labs
        </Link>
        <Link className="footer-link" to="/#newsletter">
          Newsletter
        </Link>
      </div>
      <div className="column">
        <h5>Áreas de Trabajo</h5>
        <Link className="footer-link" to="/nosotros/areas-trabajo/#ninas-ninos">
          Niñas, Niños y Acompañantes
        </Link>
        <Link className="footer-link" to="/nosotros/areas-trabajo/#comunidades">
          Comunidades de Juego
        </Link>
        <Link
          className="footer-link"
          to="/nosotros/areas-trabajo/#agentes-cambio"
        >
          Agentes de Cambio
        </Link>
        <Link
          className="footer-link"
          to="/nosotros/areas-trabajo/#organizacion"
        >
          Organización
        </Link>
        <Link className="footer-link" to="/nosotros/areas-trabajo/#entorno">
          Entorno
        </Link>
      </div>
      <div className="column">
        <h5>Colaborar</h5>
        <Link
          className="footer-link"
          to="/servicios-profesionales/meiquer-labs-historias"
        >
          Escuelas
        </Link>
        <Link
          className="footer-link"
          to="/servicios-profesionales/meiquer-labs-historias"
        >
          Empresas
        </Link>
        <Link
          className="footer-link"
          to="/servicios-profesionales/meiquer-labs-historias"
        >
          Organizaciones
        </Link>
        <Link
          className="footer-link"
          to="/servicios-profesionales/meiquer-labs-historias"
        >
          Gobierno
        </Link>
      </div>
    </div>
  </footer>
)

export default Footer

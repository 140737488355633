import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import MeiquerLogo from "../assets/logos/logo-meiquer.svg"

import "../styles/navbar.scss"

const NavBar = ({ pathname }) => {
  const [opened, setOpen] = useState(false)
  const [parentsHidden, setParentsHidden] = useState(true)

  const parentsLinks = [
    { name: "Todos los Servicios", link: "/mamas-y-papas" },
    { name: "Espiral Meiquer", link: "/espiral-meiquer" },
    { name: "Meiquer TV", link: "/meiquer-tv" },
    { name: "Experiencias Meiquer", link: "/experiencias-meiquer" },
    { name: "Estaciones de Juego", link: "/estaciones-juego" },
  ]

  const isSectionActive = links =>
    links.map(_ => _.link).find(elem => elem === pathname)

  const renderLinks = links =>
    links.map(({ name, link }, idx) => (
      <Link
        key={idx}
        className="navbar-item"
        activeClassName="active"
        to={link}
      >
        {name}
      </Link>
    ))

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src={MeiquerLogo} alt="Meiquer MX Logo" />
        </Link>

        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setOpen(!opened)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${opened ? "is-active" : ""}`}
      >
        <div className="navbar-end">
          <Link className="navbar-item" activeClassName="active" to="/nosotros">
            Nosotros
          </Link>
          <div
            className={`navbar-item has-dropdown is-hoverable ${
              isSectionActive(parentsLinks) ? "active" : ""
            }`}
            onClick={() => setParentsHidden(!parentsHidden)}
          >
            <span className="navbar-link is-arrowless">
              Mamás, Papás y Tutores
            </span>
            <div
              className={`navbar-dropdown ${
                parentsHidden ? "is-hidden-mobile" : ""
              }`}
            >
              {renderLinks(parentsLinks)}
            </div>
          </div>
          <Link
            className="navbar-item"
            activeClassName="active"
            to="/servicios-profesionales"
          >
            Organizaciones y Empresas
          </Link>
          <Link
            className="navbar-item"
            activeClassName="active"
            to="/contacto-ventas"
          >
            Contacto
          </Link>
        </div>
      </div>
    </nav>
  )
}

NavBar.propTypes = {
  pathname: PropTypes.string,
}

NavBar.defaultProps = {
  pathname: "",
}

export default NavBar

import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../styles/social-media.scss"
import "../styles/footer.scss"

const SocialMedia = ({ dark }) => (
  <div className={`social-media ${dark ? "dark" : "bright"}`}>
    <a
      className="social-link"
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.youtube.com/channel/UCUYXSMJa2nxApcfcO1GXJug"
    >
      <FontAwesomeIcon icon={["fab", "youtube"]} />
    </a>
    <a
      className="social-link"
      target="_blank"
      rel="noopener noreferrer"
      href="https://instagram.com/estacionmeiquer/"
    >
      <FontAwesomeIcon icon={["fab", "instagram"]} />
    </a>
    <a
      className="social-link"
      target="_blank"
      rel="noopener noreferrer"
      href="https://facebook.com/estacionmeiquer"
    >
      <FontAwesomeIcon icon={["fab", "facebook"]} />
    </a>
    <a
      className="social-link"
      target="_blank"
      rel="noopener noreferrer"
      href="https://linkedin.com/company/estacionmeiquer"
    >
      <FontAwesomeIcon icon={["fab", "linkedin"]} />
    </a>
  </div>
)

SocialMedia.propTypes = {
  dark: PropTypes.bool,
}

SocialMedia.defaultProps = {
  dark: false,
}

export default SocialMedia
